import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex';
import { HTTPClient } from "api/HTTPClient";
import { getField, updateField } from "vuex-map-fields";

const httpClient = new HTTPClient();

Vue.use(Vuex)

const store = new Vuex.Store({
  getters: {
    getField,
  },
  state: { },
  mutations: {
    updateField,
  },
  actions: { }
});

export default store;
