
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class PharmacyForm extends Vue {
  @Prop({default: []}) usStates
  @Prop({default: []}) usCities
  @Prop({default: {}}) object

  stateSelectOption
  citySelectOption

  mounted() {
    const vm = this

    vm.stateSelectOption = document.getElementById("pharmacy_state") as HTMLSelectElement
    vm.citySelectOption = document.getElementById("pharmacy_city") as HTMLSelectElement

    vm.bindStateData()
    vm.handleStateChanged()

    vm.stateSelectOption.value = vm.object.state
    var event = new Event('change');
    vm.stateSelectOption.dispatchEvent(event);
    vm.citySelectOption.value = vm.object.city
  }

  private bindStateData() {
    const vm = this

    const option = document.createElement("option");
    option.text = "-- select state --"
    vm.stateSelectOption.add(option);

    Object.values(vm.usStates).forEach((state: string) => {
      const option = document.createElement("option");
      option.text = state;
      option.value = state;
      vm.stateSelectOption.add(option);
    })
  }

  private handleStateChanged() {
    const vm = this
    vm.stateSelectOption.addEventListener('change', (event) => {
      vm.citySelectOption.textContent = ''

      const cities = vm.usCities[event.target.value] || []

      cities.forEach((city: string) => {
        const option = document.createElement("option");
        option.text = city;
        option.value = city;
        vm.citySelectOption.add(option);
      })
    });
  }
}
