import axios, {
    AxiosRequestConfig,
    AxiosInstance,
    AxiosResponse,
    AxiosError,
} from "axios";

const csrfToken = document.querySelector("[name=csrf-token]").attributes[
    "content"
    ].value;

axios.defaults.headers.common["X-CSRF-TOKEN"] = csrfToken;

export class HTTPClient {
    private client: AxiosInstance;
    private baseURL: string;
    private apiURL: string;

    constructor() {
        this.apiURL = process.env.VUE_APP_API_URL || "/";

        this.client = axios.create({
          baseURL: this.apiURL
        });

        this.setupClient();
    }

    public request<T>(config: AxiosRequestConfig): Promise<AxiosResponse<T>> {
        return this.client.request(config);
    }

    private setupClient() {
      this.client.interceptors.response.use(
        (response) => response,
        (error: Error) => {
            if (!this.isAxiosError(error)) {
                return Promise.reject(error);
            }

            if (!this.isAccessTokenExpired(error)) {
                return Promise.reject(error);
            }
        },
      );
    }

    private isAxiosError(error: Error): error is AxiosError {
        const {isAxiosError}: AxiosError = error as AxiosError;

        return isAxiosError;
    }

    private isAccessTokenExpired(error: AxiosError): boolean {
        return error.config && error.response && error.response.status === 401;
    }
}
