
import { Vue, Component, Prop, Watch } from "vue-property-decorator";

@Component
export default class ImportFileList extends Vue {
  get channels() {
    const vm = this;
    return {
      ImportProgressChannel: {
        connected() {
          console.log('connected');
        },
        received(data) {
          let importFile = JSON.parse(data)

          let percentText = document.querySelector(`[id="${importFile.job_id}"] > td:nth-child(1)`) as HTMLElement
          let status = document.querySelector(`[id="${importFile.job_id}"] > td:nth-child(2)`) as HTMLElement
          let completedRecords = document.querySelector(`[id="${importFile.job_id}"] > td:nth-child(5)`) as HTMLElement
          let failureRecords = document.querySelector(`[id="${importFile.job_id}"] > td:nth-child(6)`) as HTMLElement

          if (percentText) {
            percentText.innerHTML = importFile.percent
          }

          if (completedRecords) {
            completedRecords.innerHTML = importFile.completed_records
          }

          if (failureRecords) {
            failureRecords.innerHTML = importFile.failure_records
          }

          if (status) {
            status.innerHTML = importFile.status_text
          }
        }
      }
    };
  }

  created() {
    const vm = this
    vm.$cable.subscribe({
      channel: 'ImportProgressChannel',
    });
  }

  beforeDestroy() {
    const vm = this
    vm.$cable.unsubscribe('ImportProgressChannel');
  }
}
