import Vue from "vue/dist/vue.esm";
import TurbolinksAdapter from "vue-turbolinks";
import ActionCableVue from 'actioncable-vue';

import store from "store";
import TurbolinksCustom from "../utilities/tubolinks-custom";

import ImportFileList from "../pages/import_file_list"
import HealthcareFacilityForm from "../pages/healthcare_facility_form"
import PharmacyForm from "../pages/pharmacy_form"

Vue.prototype.$turbolinks = TurbolinksCustom;
Vue.use(TurbolinksAdapter);
Vue.use(require('vue-moment'));
Vue.use(ActionCableVue, {
  debug: true,
  debugLevel: 'error',
  connectionUrl: process.env.VUE_APP_CONNECTION_URL,
  connectImmediately: true,
});

document.addEventListener("turbolinks:load", () => {
  if (document.querySelector('[data-behavior="vue"]')) {
    new Vue({
      el: '[data-behavior="vue"]',
      store,
      components: {
        ImportFileList,
        HealthcareFacilityForm,
        PharmacyForm
      },
    });
  }
});
